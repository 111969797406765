var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
    handler: _vm.onIntersectPageLayout,
    options: {
      threshold: 1.0
    }
  }),expression:"{\n    handler: onIntersectPageLayout,\n    options: {\n      threshold: 1.0\n    }\n  }"}],staticClass:"page-layout",style:(`${
    _vm.layoutType == 'b' ? `background-image: url('${_vm.bannerSrc}');` : ''
  }`)},[_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
      handler: _vm.onIntersectPageLayout,
      options: {
        threshold: 1.0
      }
    }),expression:"{\n      handler: onIntersectPageLayout,\n      options: {\n        threshold: 1.0\n      }\n    }"}],staticClass:"page-layout__content-wrapper d-flex align-end",style:(_vm.layoutType == 'a' || 'x'
        ? `background-image: url('${_vm.bannerSrc}'); background-position: ${_vm.backgroundImagePosition}`
        : '')},[_c('div',{staticClass:"content-wrapper",class:`${_vm.color}--text`},[_c('div',{staticClass:"pb-5 pb-md-10"},[(!_vm.hideLogo)?_c('router-link',{attrs:{"to":{ name: _vm.routeName.HOME }}},[_c('transition',{attrs:{"name":"slide-fade-y"}},[_c('v-img',{directives:[{name:"show",rawName:"v-show",value:(_vm.isIntersectPageLayout),expression:"isIntersectPageLayout"}],staticClass:"mb-4 mb-md-6",attrs:{"src":"/assets/image/logo.png","max-width":_vm.logoMaxWidth,"alt":"logo"}})],1)],1):_vm._e(),_c('transition',{attrs:{"name":"slide-fade-y"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isIntersectPageLayout),expression:"isIntersectPageLayout"}],staticClass:"font-weight-bold page-layout__title text-body-1 text-sm-h6 text-md-h5 text-xl-h4 enter-delay-500ms text-capitalize"},[_vm._v(" "+_vm._s(_vm.pageTitle)+" ")])]),_c('v-divider',{staticClass:"my-2 my-md-3 page-layout__divider",class:_vm.pageTitleDividerClass,attrs:{"id":"pageLayoutDivider"}}),_c('transition',{attrs:{"name":"slide-fade-y"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isIntersectPageLayout),expression:"isIntersectPageLayout"}],staticClass:"text-small text-sm-body-2 text-md-body-1 text-xl-h6 font-weight-regular enter-delay-750ms text-capitalize",style:(_vm.CustomCss)},[_vm._v(" "+_vm._s(_vm.pageSubtitle)+" ")])])],1)])]),(['b', 'c'].includes(_vm.layoutType) && !_vm.$vuetify.breakpoint.mobile)?_c('v-divider',{staticClass:"page-layout__divider-2",attrs:{"id":"pageLayoutDivider2"}}):_vm._e(),_c('div',{class:['a', 'c'].includes(_vm.layoutType) ? 'secondary' : ''},[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }