<template>
  <PageLayout
    :bannerSrc="bannerSrc"
    :pageTitle="pageTitle"
    :pageSubtitle="pageSubtitle"
    pageTitleDividerClass="primary"
    :color="color"
    layoutType="x"
    :hideLogo="true"
  >
    <div class="content-wrapper pt-6 pb-12">
      <template v-for="(i, index) in pressCoveragelist">
        <div :key="index" class="coverageList">
          <div class="py-10">
            <v-row class="justify-space-between align-center pb-lg-10">
              <img class="coverageLogo" :src="i.logo" alt="" width="20%" />
              <div class="coverageDateTime" v-html="i.dateTime"></div>
            </v-row>
            <div class="py-lg-10 coverageTitle py-5" v-html="i.title"></div>
            <div class="coverageContent" v-html="i.content"></div>
            <div class="tow-button readMore text-right pt-3">
              <router-link
                :to="{
                  name: 'press-coverage-detail',
                  params: { id: i.id }
                }"
                class="topbtn main--btn"
              >
                READ MORE
              </router-link>
              <!-- <div class="red">{{ routeName }} ABOUT_COOKX</div> -->
            </div>
          </div>
          <br />
          <hr class="my-lg-10" />
        </div>
      </template>
    </div>
  </PageLayout>
</template>

<script>
  // import axios from 'axios';
  import PageLayout from '@/views/layout/PageLayout';
  // import AppPDFViewer from '@/views/components/base/AppPDFViewer';
  import { Configuration /*uiHelper */ } from '@/core/utils';

  export default {
    name: 'pressCoverageListTemplate',
    components: {
      PageLayout
      // AppPDFViewer
    },
    props: {
      bannerSrc: {
        type: String,
        default: ''
      },
      pageTitle: {
        type: String,
        default: ''
      },
      pageSubtitle: {
        type: String,
        default: ''
      },
      color: {
        type: String,
        default: 'surface'
      },
      topic: {
        type: String,
        default: ''
      },
      pressCoveragelist: {
        type: Array,
        default: () => []
      }
    },
    data: () => ({
      spacesUrl: Configuration.value('spacesUrl'),
      assetEnvironment: Configuration.value('assetEnvironment'),
      noPDFContent: false
    })
  };
</script>

<style lang="scss"></style>
