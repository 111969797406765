<template>
  <div
    v-intersect="{
      handler: onIntersectPageLayout,
      options: {
        threshold: 1.0
      }
    }"
    class="page-layout"
    :style="`${
      layoutType == 'b' ? `background-image: url('${bannerSrc}');` : ''
    }`"
  >
    <div
      v-intersect="{
        handler: onIntersectPageLayout,
        options: {
          threshold: 1.0
        }
      }"
      :style="
        layoutType == 'a' || 'x'
          ? `background-image: url('${bannerSrc}'); background-position: ${backgroundImagePosition}`
          : ''
      "
      class="page-layout__content-wrapper d-flex align-end"
    >
      <div :class="`${color}--text`" class="content-wrapper">
        <div class="pb-5 pb-md-10">
          <!-- Logo -->
          <router-link v-if="!hideLogo" :to="{ name: routeName.HOME }">
            <transition name="slide-fade-y">
              <v-img
                v-show="isIntersectPageLayout"
                src="/assets/image/logo.png"
                :max-width="logoMaxWidth"
                alt="logo"
                class="mb-4 mb-md-6"
              ></v-img>
            </transition>
          </router-link>
          <!-- Logo -->

          <!-- Page Title -->
          <transition name="slide-fade-y">
            <div
              v-show="isIntersectPageLayout"
              class="font-weight-bold page-layout__title text-body-1 text-sm-h6 text-md-h5 text-xl-h4 enter-delay-500ms text-capitalize"
            >
              {{ pageTitle }}
            </div>
          </transition>
          <!-- Page Title -->

          <!-- Divider -->

          <v-divider
            id="pageLayoutDivider"
            class="my-2 my-md-3 page-layout__divider"
            :class="pageTitleDividerClass"
          ></v-divider>
          <!-- Divider -->

          <!-- Page Subtitle -->
          <transition name="slide-fade-y">
            <div
              v-show="isIntersectPageLayout"
              :style="CustomCss"
              class="text-small text-sm-body-2 text-md-body-1 text-xl-h6 font-weight-regular enter-delay-750ms text-capitalize"
            >
              {{ pageSubtitle }}
            </div>
          </transition>
          <!-- Page Subtitle -->
        </div>
      </div>
    </div>

    <v-divider
      id="pageLayoutDivider2"
      v-if="['b', 'c'].includes(layoutType) && !$vuetify.breakpoint.mobile"
      class="page-layout__divider-2"
    ></v-divider>

    <div :class="['a', 'c'].includes(layoutType) ? 'secondary' : ''">
      <slot />
    </div>
  </div>
</template>

<script>
  import { ROUTE_NAME } from '@/core/constants';

  export default {
    name: 'PageLayout',
    props: {
      hideLogo: {
        Type: Boolean,
        default: false
      },
      bannerSrc: {
        type: String,
        default: ''
      },
      pageTitle: {
        type: String,
        default: ''
      },
      pageSubtitle: {
        type: String,
        default: ''
      },
      CustomCss: {
        type: String,
        default: ''
      },
      pageTitleDividerClass: {
        type: String,
        default: 'surface'
      },
      color: {
        type: String,
        default: 'surface'
      },
      layoutType: {
        type: String,
        default: 'a'
      },
      backgroundImagePosition: {
        type: String,
        default: 'top'
      }
    },
    data: () => ({
      routeName: ROUTE_NAME,
      isIntersectPageLayout: false
    }),
    computed: {
      logoMaxWidth() {
        if (this.$vuetify.breakpoint.mdAndUp) {
          return 160;
        } else if (this.$vuetify.breakpoint.smOnly) {
          return 120;
        } else {
          return 90;
        }
      }
    },
    methods: {
      onIntersectPageLayout(entries, observer, isIntersecting) {
        const targetList = [
          document.querySelector('#pageLayoutDivider'),
          document.querySelector('#pageLayoutDivider2')
        ];

        if (isIntersecting) {
          this.isIntersectPageLayout = true;
          targetList.forEach((target) => {
            if (target) target.classList.add('active');
          });
        }
      }
    }
  };
</script>

<style lang="scss">
  .page-layout {
    height: 100%;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;

    .page-layout__content-wrapper {
      height: 300px;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .page-layout__divider {
      max-width: 0;
      &.active {
        max-width: 40px;
        transition: all 1s ease-in-out;
        transition-delay: 3s;
      }
    }

    .page-layout__divider-2 {
      max-width: 0;
      &.active {
        max-width: 40%;
        transition: all 4.5s ease-in-out;
      }
    }
  }

  @media (max-width: 959px) {
    .page-layout {
      .page-layout__content-wrapper {
        height: 200px;
        .page-layout__divider {
          &.active {
            max-width: 30px;
          }
        }
      }
    }
  }

  @media (max-width: 599px) {
    .page-layout {
      .page-layout__content-wrapper {
        height: 160px;
        .page-layout__divider {
          &.active {
            max-width: 20px;
          }
        }
      }
    }
  }
</style>
