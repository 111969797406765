<template>
  <div>
    <PressCoveragePageTemplate
      bannerSrc="/assets/image/pressCoverage_banner.png"
      :pageTitle="$t('label.pressCoverage')"
      :pageSubtitle="$t('label.pressCoverageDetial')"
      :isPDF="true"
      topic="pressCoverageList"
      :pressCoveragelist="pressCoveragelist"
    ></PressCoveragePageTemplate>
    <!-- {{ pressCoveragelist }} -->
  </div>
</template>

<script>
  import PressCoveragePageTemplate from '@/views/components/pressCoverage/pressCoverageListTemplate';
  import { PRESS_COVERAGE_GET_PRESS_COVERAGES } from '@/core/store/press-coverage.module';

  export default {
    name: 'PressCoverageList',
    components: {
      PressCoveragePageTemplate
    },
    data: () => ({
      content: null,
      pressCoveragelist: null
    }),
    created() {
      this.initPressCoverage();
    },
    computed: {
      getPressCoverageComplete() {
        return this.$store.state.pressCoverage.pressCoverages.complete;
      }
    },
    watch: {
      getPressCoverageComplete() {
        this.passPressCoverage();
      }
    },
    methods: {
      getPressCoverages() {
        this.$store.dispatch(PRESS_COVERAGE_GET_PRESS_COVERAGES);
        let response = this.$store.state.pressCoverage.pressCoverages;
        if (response.complete) {
          this.passPressCoverage();
        }
      },

      initPressCoverage() {
        this.getPressCoverages();
      },
      passPressCoverage() {
        let response = this.$store.state.pressCoverage.pressCoverages;
        if (response.complete) {
          let data = response.data;
          this.pressCoveragelist = data;
        }
      }
    }
  };
</script>

<style></style>
